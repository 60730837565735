/* eslint no-console: off */

import {
  AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse,
} from 'axios';

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  console.info('[request]', config.data);
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  console.error('[request error]', { message: error.message, status: error.code });
  return Promise.reject(error);
};

const onResponse = (response): AxiosResponse => {
  console.info('[response]', { data: response.data, statusCode: response.status });
  return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  console.error('[response error]', { message: error.message, status: error.code });
  return Promise.reject(error);
};

export default function assignInterceptors(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}
