import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import assignInterceptors from '../util/axios';

class HttpRequest {
  client: AxiosInstance;

  constructor() {
    const client = axios.create();
    assignInterceptors(client);
    this.client = client;
  }

  get(url: string, options = {}) {
    return this.client.get(url, options);
  }

  async post<Type>(url: string, payload: Type) {
    const options: AxiosRequestConfig = {
      data: payload,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      url,
    };

    return this.client.request(options);
  }

  put(url: string, payload: string) {
    const options: AxiosRequestConfig = {
      data: payload,
      method: 'PUT',
      url,
    };

    return this.client.request(options);
  }

  delete(url) {
    const options: AxiosRequestConfig = {
      method: 'DELETE',
      url,
    };

    return this.client.request(options);
  }
}

export default HttpRequest;
