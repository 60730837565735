// Node Modules
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import JoinUsForm from '../functional/JoinUsForm';

// Styles
import { colors, space } from '../../../style/design-system';

type FormWrapperProps = {
  buttonText?: string,
  className: string,
  cta: string,
  hideCourses?: boolean,
  hideInterest?: boolean,
  interest?: string,
  selectedCourse?: string
};

const formWrapperDefaultProps = {
  buttonText: 'Join Us',
  className: undefined,
  cta: 'Join Our Care Community',
  hideCourses: false,
  hideInterest: false,
  interest: 'Not Applicable',
  selectedCourse: 'Not Applicable',
};

const FormWrapper = ({
  buttonText, className, cta, hideCourses, hideInterest, interest, selectedCourse,
}: FormWrapperProps) => (
  <div className={className} id="join-us-form-wrapper">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2">
          <h3 className="mb-8">{cta}</h3>
          <div className="form-wrapper">
            <JoinUsForm
              buttonText={buttonText}
              hideCourses={hideCourses}
              hideInterest={hideInterest}
              interest={interest}
              selectedCourse={selectedCourse} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

FormWrapper.propTypes = {
  className: PropTypes.string,
  cta: PropTypes.string,
  hideCourses: PropTypes.bool,
  hideInterest: PropTypes.bool,
};

FormWrapper.defaultProps = formWrapperDefaultProps;

export default styled(FormWrapper)`
  background-color: ${colors.dev.gray.h()};
  padding: ${space.h} 0;
  text-align: center;
  .form-wrapper {
    background: #fff;
    padding: ${space.h};
  }
`;
