/* eslint react/jsx-props-no-spreading: off */

// Node Modules
import React, { useState } from 'react';
import cx from 'classnames';
import styled, { keyframes } from 'styled-components';
import { useForm } from 'react-hook-form';
import { navigate } from 'gatsby';

import { urlsNetlifyFunctions } from '../../../enums';
import { HttpRequestService } from '../../../services';

// Style
import { colors, fontSize, space } from '../../../style/design-system';

// form field names
enum fieldNames {
  COURSE = 'course',
  EMAIL = 'email',
  FIRST_NAME = 'firstName',
  INTEREST = 'interest',
  LAST_NAME = 'lastName',
  ORGANIZATION = 'organization',
}

// form data structure
type FormData = {
  [fieldNames.COURSE]: string;
  [fieldNames.FIRST_NAME]: string;
  [fieldNames.LAST_NAME]: string;
  [fieldNames.EMAIL]: string;
  [fieldNames.INTEREST]: string;
  [fieldNames.ORGANIZATION]: string;
};

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

type JoinUsFormProps = {
  buttonText?: string,
  className?: string,
  hideCourses?: boolean,
  hideInterest?: boolean,
  interest?: string,
  // todo: I think this should be an enum, not a string. what are the acceptable values?
  selectedCourse?: string
};

const joinUsFormDefaultProps = {
  buttonText: '',
  className: '',
  hideCourses: false,
  hideInterest: false,
  interest: 'Not Applicable',
  selectedCourse: 'Not Applicable',
};

const JoinUsForm = ({
  buttonText,
  className,
  hideInterest,
  hideCourses,
  interest,
  selectedCourse,
}: JoinUsFormProps) => {
  // react-hook-form setup
  const { formState: { errors }, handleSubmit, register } = useForm<FormData>();
  const [irrecoverableFormError, setIrrecoverableFormError] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // onSubmit handler
  const onSubmit = handleSubmit(async (data) => {
    setIsSubmitting(true);

    const dataCopy = data;
    const submissionErrors: {name: string, err: Error|null}[] = [];

    // hide interest only true when user does not select an interest
    // i.e. the courses page, in which case we pass a prop to override the form select
    if (hideInterest) {
      dataCopy.interest = interest;
    }

    // hide courses only true when user does not select a course
    // i.e. all pages except courses page
    if (hideCourses) {
      dataCopy.course = selectedCourse;
    }

    const client = new HttpRequestService();

    // send notification to teams
    try {
      await client.post<FormData>(urlsNetlifyFunctions.JOIN_US, dataCopy);
    }
    catch (err) {
      submissionErrors.push({ err, name: 'teams' });
    }

    // send email notification
    try {
      const subject = 'New Submission from Care Pays Back';
      const body = `
        A new submission from Care Pays Back has been received.

        Name: ${dataCopy.firstName} ${dataCopy.lastName}
        Email: ${dataCopy.email}
        Organization: ${dataCopy.organization}
        Interest: ${dataCopy.interest}
        Course: ${dataCopy.course}
      `;
      await client.post<{body: string, subject: string}>(urlsNetlifyFunctions.EMAIL, {
        body,
        subject,
      });
    }
    catch (err) {
      submissionErrors.push({ err, name: 'email' });
    }

    if (submissionErrors.length >= 2) {
      setIrrecoverableFormError(true);
      setIsSubmitting(false);
      return;
    }

    if (submissionErrors.length > 0) {
      let errString = '';
      submissionErrors.forEach(((e) => {
        errString += `${e.name},`;
        // eslint-disable-next-line no-console
        console.log(`error dispatching ${e.name} notification\n`, e.err);
      }));
      navigate(`/confirmation/?err=${errString.slice(0, -1)}`);
    }

    else {
      navigate('/confirmation');
    }
  });

  const renderErrorMessage: (fieldName: fieldNames) => string = (fieldName) => {
    const err = errors[fieldName];
    if (err) {
      switch (err.type) {
        case 'required':
          return 'This field is required.';
        case 'email':
          return 'Please enter a valid email address.';
        default:
          return '';
      }
    }
    return '';
  };

  // render the component
  return (
    <div className={className}>
      {irrecoverableFormError && (
        <p className="form-error text-left">
          Oh no! There was an error on our end during form submission. Please try again.
        </p>
      )}
      <form onSubmit={onSubmit}>

        <div className="form-group">
          <label className="" htmlFor="firstNameField">
            <span className="">First Name</span>
            <input
              name={fieldNames.FIRST_NAME}
              id="firstNameField"
              type="text"
              className="form-control"
              {...register(fieldNames.FIRST_NAME, { required: true })} />
          </label>
          <span className="error-msg">{renderErrorMessage(fieldNames.FIRST_NAME)}</span>
        </div>

        <div className="form-group">
          <label className="" htmlFor="lastNameField">
            <span className="">Last Name</span>
            <input
              name={fieldNames.LAST_NAME}
              id="lastNameField"
              type="text"
              className="w-100 form-control"
              {...register(fieldNames.LAST_NAME, { required: true })} />
          </label>
          <span className="error-msg">{renderErrorMessage(fieldNames.LAST_NAME)}</span>
        </div>

        <div className="form-group">
          <label className="" htmlFor="emailField">
            <span className="">Email</span>
            <input
              name={fieldNames.EMAIL}
              id="emailField"
              type="email"
              className="form-control"
              {...register(fieldNames.EMAIL, { pattern: emailRegex, required: true })} />
          </label>
          <span className="error-msg">{renderErrorMessage(fieldNames.EMAIL)}</span>
        </div>

        <div className="form-group">
          <label className="" htmlFor="organizationField">
            <span className="">Organization</span>
            <input
              name={fieldNames.ORGANIZATION}
              id="organizationField"
              type="text"
              className="form-control"
              {...register(fieldNames.ORGANIZATION, { required: true })} />
          </label>
          <span className="error-msg">{renderErrorMessage(fieldNames.ORGANIZATION)}</span>
        </div>

        <div id="interest-wrapper" className="hidden">
          <label htmlFor="select-interest">
            <span className="">I want to:</span>
            <select id="select-interest" className="form-control" name={fieldNames.INTEREST} {...register(fieldNames.INTEREST, { required: true })}>
              <option value="Not Applicable">Select One</option>
              <option id="interestPartnerField" value="Partner">Partner</option>
              <option id="interestNetworkField" value="Network">Network</option>
              <option id="interestContributeField" value="Contribute">Contribute</option>
              <option id="interestSubscribeField" value="Subscribe">Subscribe</option>
            </select>
          </label>
        </div>

        <div id="courses-wrapper" className={hideCourses ? 'hidden' : 'form-group'}>
          <label htmlFor="select-course">
            <span className="">Course</span>
            <select id="select-course" className="form-control" name={fieldNames.COURSE} {...register(fieldNames.COURSE, { required: true })}>
              <option value="Not Applicable">-- Select One --</option>
              <option id="virtualFit" value="virtual-fit">Virtual Fit</option>
              <option id="meaningfulConnections" value="meaningful-connections">Meaningful Connections</option>
              <option id="assumingRemoteControl" value="assuming-remote-control">Assuming Remote Control</option>
            </select>
          </label>
        </div>

        <input
          className={cx('btn btn-submit form-control mt-7')}
          type="submit"
          value={buttonText}
          disabled={isSubmitting} />
      </form>
    </div>
  );
};

JoinUsForm.defaultProps = joinUsFormDefaultProps;

const formSubmitAnimation = keyframes`
  from {
    background-color: ${colors.cpb.yellow};
    transform: rotateZ(-1deg);
  }
  to {
    background-color: ${colors.cpb.yellowDarkened};
    transform: rotateZ(1deg);
  }
`;

export default styled(JoinUsForm)`
  text-align: left;
  .btn-submit {
    background-color: ${colors.cpb.yellow};
    font-weight: bold;
    &:hover {
      background-color: ${colors.cpb.yellowDarkened};
    }
    &[disabled] {
      animation: ${formSubmitAnimation} .4s infinite alternate;
      background-color: ${colors.dev.gray.e()};
      cursor: progress;
    }
  }
  .hidden {
    visibility: hidden;
    height: 0;
    margin: 0;
    position: absolute;
    overflow: hidden;
  }
  .error-msg {
    color: ${colors.umaRed};
    font-size: ${fontSize.c};
  }
  label {
    width: 100%;
  }

  .form-error {
    background-color: ${colors.dev.red.i()};
    color: ${colors.dev.red.d()};
    font-size: 1rem;
    padding: ${space.c};
  }
`;
